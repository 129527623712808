import { useContext, useEffect, useState } from "react";
import { BiEdit } from "react-icons/bi";
import { FaGreaterThan } from "react-icons/fa";
import { GrView } from "react-icons/gr";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AlertContext from "../../../context/Alert/AlertContext";
import AuthContext from "../../../context/Auth/AuthContext";
import DataTable2 from "../../../context/components/DataTable2";
import axiosPrivate from "../../../hooks/axiosPrivate";
import LoaderContext from "../../../context/loader/LoaderContext";
import styles from "./Order.module.css"

const Order = () => {
  const { setIsLoading } = useContext(LoaderContext);
  const navigate = useNavigate();
  const { checkPermissionExists, authPermissions } = useContext(AuthContext);
  const { alertMessage, setAlertMessage } = useContext(AlertContext);
  const [orderState, setOrderState] = useState([]);


  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const date = new Date(inputDate);
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day} ${month} ${year}`;
  }

  

  const userDetails = JSON.parse(localStorage.getItem('userDetails'))
  const userRole = userDetails.roles
  
  const headers1 = [
    { name: "ORDER NO.", field: "order_no", sortable: true, classKey: "" },
    {
      name: "DEALER NAME",
      field: "dealer_name",
      sortable: true,
      classKey: "",
    },
    {
      name: "ORDERED BY",
      field: "order_punched_by",
      sortable: true,
      classKey: "",
    },
    {
      name: "Order Date",
      field: "order_date",
      sortable: true,
      classKey: "",
    },
    { name: "AMOUNT (₹)", field: "total_amount", sortable: true, classKey: "" },
    {
      name: "STATUS",
      field: "order_status",
      sortable: true,
      classKey: "",
    },

    { name: "ACTION", field: "action", classKey: "" },
  ];


  const headers2 = [
    { name: "ORDER NO.", field: "order_no", sortable: true, classKey: "" },
    
    {
      name: "Order Date",
      field: "order_date",
      sortable: true,
      classKey: "",
    },
    { name: "AMOUNT (₹)", field: "total_amount", sortable: true, classKey: "" },
    {
      name: "STATUS",
      field: "order_status",
      sortable: true,
      classKey: "",
    },

    { name: "ACTION", field: "action", classKey: "" },
  ];
  const searchItems1 = ["order_no", "dealer_name", "order_punched_by", "order_date", "total_amount", "order_status_2"];
  const searchItems2 = ["order_no", "order_date", "total_amount", "order_status_2"];

  // get All nmStaff api
  useEffect(() => {
    setIsLoading(true);
    axiosPrivate
      .get(`orders`)
      .then((order) => {
        const orderData = order?.data?.data?.map((value, key) => {
         
          let order_status_1;

        

          order_status_1 =
            value.order_status === "confirmed" ? (
              <button
                className="tag  button-status"
                style={{ background: "#b6cb43", color: "white" }}
              >
                Confirmed
              </button>
              
            ) : value.order_status === "cancelled" ? (
              <button
                className="tag  button-status"
                style={{ background: "#5a6268", color: "white" }}
              >
                Cancelled
              </button>
              
            ) : (
              <button className="tag tag-warning button-status">Pending</button>
            );
          value["order_no"] = "#" + value?.order_no;
          value['order_status_2'] = value?.order_status;
         
          value["order_status"] = order_status_1;
          value["order_date"] = formatDate(value?.order_date);
          value["order_punched_by"] =
            value.order_punched_by == "admin"
              ? "Admin"
              : value.order_punched_by == "area_manager"
              ? "Area Manager"
              : value.order_punched_by == "dealer"
              ? "Dealer"
              : "";
          return value;
        });

        setOrderState(orderData);
        setIsLoading(false);
      })

      .catch((error) => {
        console.error(error);
        setIsLoading(false);
      });
  }, []);



  const newData = orderState.map((value,key) => {
    let buttons = [];
    buttons.push(
      ( (value.order_status_2 == "to_logistics" || value.order_status_2 == "confirmed" || value.order_status_2 == "cancelled")
          && authPermissions.includes("Order-View") )? (
             <Link
               key={`viewButton##${key}`}
               type="button"
               to={`/admin/order/edit/${value.order_uid}`}
               className="btn btn-icon"
               // title="Edit"
             >
               <GrView style={{ color: "blue" }} size={18} />
             </Link>
           )
         : // Change to view icon
         authPermissions.includes("Order-Edit") ?(
             <Link
               key={`editButton##${key}`}
               type="button"
               to={`/admin/order/edit/${value.order_uid}`}
               className="btn btn-icon"
               // title="Edit"
             >
               <BiEdit style={{ color: "green" }} size={18} />
             </Link>
           )
           : null
           // Use edit icon for other statuses
     );
     value["action"] = buttons.length > 0 ? buttons : "-";
     return value;
  })

  // alert message
  useEffect(() => {
    if (alertMessage != null) {
      toast(alertMessage.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setAlertMessage(null);
    }
  }, [alertMessage]);

  return (
    <>
      <div className="section-body">
        <div className="container-fluid" style={{ padding: "0px" }}>
          <div className="mx-sm-3 mx-0">
            {/* heading and add button */}
            <div className="d-flex justify-content-between align-items-center ">
              {/* hearder breadcrumb */}
              <div className="card-header">
                <strong className="top-left-heading">Orders</strong>

                <ol
                  className="headerroutes breadcrumb m-0 bg-none "
                  style={{ flexWrap: "nowrap" }}
                >
                  <li className="item color-gray">|</li>
                  <li className="item">
                    <Link
                      to="/admin/dashboard"
                      className="route-heading item mx-2"
                    >
                      Dashboard
                    </Link>
                  </li>
                  <li className="item">
                    <FaGreaterThan className="grater-than-route" />
                  </li>
                  <li className="route-heading item ml-2 mr-sm-2 mr-0 mt-1">
                    Orders
                  </li>
                </ol>
              </div>
              <div className={`header-action ${styles.addButton}`}>
                {authPermissions.includes("Order-Create") ? (
                  <Link to="/admin/order/add" className="text-decoration-none ">
                    <button
                      type="button"
                      className="btn btn-primary add_btn"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      <i className="fe fe-plus mr-sm-2 mr-1" />
                      Add
                    </button>
                  </Link>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="section-body ">
          <div className="container-fluid">
            <div className="tab-content ">
              <div
                className="tab-pane fade show active"
                id="order-list"
                role="tabpanel"
              >
                <div className="card">
                  <div className="card-body">
                    <div className="table-responsive">
                      {
                        <DataTable2
                          lists={newData}
                          headers={userRole !== "dealer" ? headers1 : headers2}
                          searchItems={userRole !== "dealer" ? searchItems1 : searchItems2}
                        />
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Order;
